<template>
	<v-container style="padding: 4px" fill-height>
		<v-layout row align-center justify-center>
			<v-flex xs12>
				<v-card text hover class="ma-3">
					<v-card-title class="ma-0 pa-0" primary-title>
						<div class="text-center layout column about">
							<v-avatar size="100" style="margin: 0 auto">
								<img src="/web/img/favicon.png" />
							</v-avatar>
							<div class="black--text headline">Nomina Newton</div>
							<div class="black--text body-2">Newton Developers</div>
							<div class="black--text caption">Copyright 2021.</div>
							<div class="black--text caption">All rights reserved.</div>
						</div>
					</v-card-title>
					<v-list>
						<v-list-item href="mailto:gerencia@eycproveedores.com" target="_blank">
							<v-list-item-action>
								<v-icon>mail</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									<a>gerencia@eycproveedores.com</a>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item href="tel:+573183383072" target="_blank">
							<v-list-item-action>
								<v-icon>phone</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									<a>+57 3183383072</a>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item href="http://eycproveedores.com" target="_blank">
							<v-list-item-action>
								<v-icon>insert_link</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									<a>eycproveedores.com</a>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
export default {};
</script>

<style>
.about {
	margin: 0;
	background-size: cover;
	background-image: url("https://eyc.eycproveedores.com/public/img/fondo%20(1).jpg");
}
</style>
