import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"padding":"4px"},attrs:{"fill-height":""}},[_c(VLayout,{attrs:{"row":"","align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VCard,{staticClass:"ma-3",attrs:{"text":"","hover":""}},[_c(VCardTitle,{staticClass:"ma-0 pa-0",attrs:{"primary-title":""}},[_c('div',{staticClass:"text-center layout column about"},[_c(VAvatar,{staticStyle:{"margin":"0 auto"},attrs:{"size":"100"}},[_c('img',{attrs:{"src":"/web/img/favicon.png"}})]),_c('div',{staticClass:"black--text headline"},[_vm._v("Nomina Newton")]),_c('div',{staticClass:"black--text body-2"},[_vm._v("Newton Developers")]),_c('div',{staticClass:"black--text caption"},[_vm._v("Copyright 2021.")]),_c('div',{staticClass:"black--text caption"},[_vm._v("All rights reserved.")])],1)]),_c(VList,[_c(VListItem,{attrs:{"href":"mailto:gerencia@eycproveedores.com","target":"_blank"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mail")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',[_vm._v("gerencia@eycproveedores.com")])])],1)],1),_c(VListItem,{attrs:{"href":"tel:+573183383072","target":"_blank"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("phone")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',[_vm._v("+57 3183383072")])])],1)],1),_c(VListItem,{attrs:{"href":"http://eycproveedores.com","target":"_blank"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("insert_link")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',[_vm._v("eycproveedores.com")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }